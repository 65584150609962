import React, { useState, useRef, useEffect } from 'react';
import './App.css';
import styled from 'styled-components';
import Webcam from 'react-webcam';
import toast from 'react-hot-toast';
import { ErrorBoundary, } from 'react-error-boundary';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { io } from 'socket.io-client';
import { scanIDOcrEndpointCallPromise } from './services/OCRService';
import Routes from './navigation/Routes';
import { getActiveSession, setActiveSession } from './store/tabStore';
import { setUserProfile } from './store/settingsStore';
import { setNotifications } from './store/notificationsStore';
import { getToken, logoutUser } from './store/authStore';
import { resetPage } from './store/reportStore';
import Error404 from './pages/Error/Error404';

const App: React.FC = () => {
    const [formInfo, setFormInfo] = useState<any>({});
    const scannerRef = useRef<Webcam>(null);
    const activeSession = useSelector(getActiveSession);

    const dispatch = useDispatch();
    
    const FallbackComponent = () => (
        <Error404></Error404>
    );

    const logout = () => {
        batch(() => {
            dispatch(setUserProfile({}));
            dispatch(setNotifications([]));
            dispatch(logoutUser());
            dispatch(resetPage());
        });
    };

    function encodeString(input:string) {
        let encodedString = '';
        for (let i = 0; i < input.length; i++) {
            const charCode = input.charCodeAt(i);
            encodedString += `${charCode}-`;
        }
        return encodedString.slice(0, -1);
    }
      
    function decodeString(encodedInput:any) {
        const encodedArray = encodedInput.split('-');
        let decodedString = '';
        for (let i = 0; i < encodedArray.length; i++) {
            const charCode = parseFloat(encodedArray[i]);
            decodedString += String.fromCharCode(charCode);
        }
        return decodedString;
    }

    const [isFocused, setFocused] = useState(false);

    useEffect(() => {
        const updateHeartbeatTime = () => {
            const currentTime = moment();
            const storedTime:any = localStorage.getItem('heartbeatTime');
            if (storedTime === undefined || storedTime === null) {
                localStorage.setItem('heartbeatTime', `D4C${encodeString(currentTime.toString())}`);
                window.location.reload();
            }
            const decodedTime = decodeString(storedTime.slice(3));
            if (currentTime.diff(decodedTime, 'seconds') > 1 && storedTime !== undefined && storedTime !== null) {
                if (!isFocused) {
                    logout();
                }
            }
            setFocused(true);
            const encodedTime = encodeString(currentTime.toString());
            localStorage.setItem('heartbeatTime', `D4C${encodedTime}`);
        };
    
        const heartbeatInterval = 600;
    
        updateHeartbeatTime();
        
        const intervalId = setInterval(updateHeartbeatTime, heartbeatInterval);
    
        return () => clearInterval(intervalId);
    }, [isFocused]);
    const scanOCRImage = async () => {
        const formData = new FormData();
        const data = {type: 'driverLicense'};
        const json = JSON.stringify(data);
        
        formData.append('file', formInfo.livePhotos[0]);
      
        formData.append('form', json);

        try {
            const response = await scanIDOcrEndpointCallPromise(formData);

            const trnOptions : (string | any)[] = [];
            const nameOptions : (string | any)[] = [];
            const dateOptions : (string | any)[] = [];
            const genderOptions : (string | any)[] = [];
            const addressOptions : (string | any)[] = [];
            const nationalityOptions : (string | any)[] = [];

            response.results.forEach((result: { name: string; percentage: number; value: string; }) => {
                // value and text object to be used easily with SelectComponent
                if (result.name === 'TRN' && result.percentage >= 0.6) trnOptions.push(result.value);
                if (result.name === 'Person' && result.percentage >= 0.6) nameOptions.push(result.value.split(' ')[0]);
                if (result.name === 'DateTime' && result.percentage >= 0.6) {
                    // const date = moment(result.value);
              
                    // dateOptions.push(date.format('YYYY-MMMM-D'));
                }
                if (result.name === 'Gender' && result.percentage >= 0.6) genderOptions.push(result.value);
                if (result.name === 'Address' && result.percentage >= 0.6) addressOptions.push(result.value);
                if (result.name === 'Nationality' && result.percentage >= 0.6) nationalityOptions.push(result.value);
            });

            const stateInfoUpdateData = {
                ...formInfo,
                scanResults: {
                    trnOptions,
                    nameOptions,
                    dateOptions,
                    genderOptions,
                    addressOptions,
                    nationalityOptions
                }
            };
            setFormInfo(stateInfoUpdateData);
        } catch (error) {
            console.error('scan.ocr.error', error);
        }
    };

    // prevent app from being used in iframes for clickjacking
    const ShowAppOnRightDomain: any = () => {
        if (window.self === window.top) {
            return (
                <ErrorBoundary FallbackComponent={FallbackComponent}>
                    <AppWrapper style={{}}>
                        <Routes />
                    </AppWrapper>
                </ErrorBoundary>
            );
        }
    };

    return (
        <ShowAppOnRightDomain></ShowAppOnRightDomain>
    );
};

export default App;

const AppWrapper = styled.div`
    flex: 1;
    height: 100vh;
    width: 100vw;
    display: flex;
`;
