/* eslint-disable import/prefer-default-export */
/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { handleRawResponse, handleError, handleResponse } from '../utils';
import endpoints from '../endpoints';
import { axiosInstance } from '../axios';

/**
 * Send request to get reports data for pie chart.
 * @return {Promise<*>}
 * @param params {startDate, endDate, agent, parish}
 */
export function getTransactionsCount(params?: any) {
    const url = endpoints.transactionCount;
    return axiosInstance.get(url, { params })
        .then(handleResponse)
        .catch(handleError);
}

/**
 * Send request to get all generated report templates.
 * @return {Promise<*>}
 * @param params { custom }
 */
export function getReports(params?: any) {
    const url = endpoints.reports;
    return axiosInstance.get(url, { params })
        .then(handleResponse)
        .catch(handleError);
}

/**
 * Send request to get a generated report template by id.
 * @return {Promise<*>}
 * @param id { id: string }
 */
export function getReportById(id: string) {
    const url = endpoints.reportById(id);
    return axiosInstance.get(url)
        .then(handleResponse)
        .catch(handleError);
}

/**
 * Send request to get a generated report template by id.
 * @return {Promise<*>}
 * @param id { userId: string }
 */
export function getReportByUserId(id: string) {
    const url = endpoints.reportByUserId(id);
    return axiosInstance.get(url)
        .then(handleResponse)
        .catch(handleError);
}

/**
 * Send request to update a generated report template by id.
 * @return {Promise<*>}
 * @param id { id: string }
 * @param data { data: any }
 */
export function updateReportById(id: string, data: any) {
    const url = endpoints.reportById(id);
    return axiosInstance.put(url, data)
        .then(handleResponse)
        .catch(handleError);
}

/**
 * Send request to update a generated report template by id.
 * @return {Promise<*>}
 * @param data { data: any }
 */
export function createReport(data: any) {
    const url = endpoints.customReport;
    return axiosInstance.post(url, data)
        .then(handleResponse)
        .catch(handleError);
}

/**
 * Send request to remove a generated report template by id.
 * @return {Promise<*>}
 * @param data { data: any }
 */
export function removeReportById(id: string) {
    const url = endpoints.reportById(id);
    return axiosInstance.delete(url)
        .then(handleResponse)
        .catch(handleError);
}

export function getAllReportByUser(id: string) {
    const url = endpoints.getAllByUser(id);
    return axiosInstance.get(url)
        .then(handleResponse)
        .catch(handleError);
}
