export const validateEmail = (email: string) : boolean => {
    let isValid = true;
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    isValid = re.test(String(email)
        .toLowerCase());
    return isValid;
};

export const validateNumber = (number: string) : boolean => {
    let valid = true;
    const re = /^([0-9]{4})([0-9]{3})([0-9]{4})$/g;
    valid = re.test(number);
    return valid;
};

export const validateInput = (input: string, name = '') : boolean => {
    let isValid = true;
    const nameRegex = /^(?!.*[-_.',]{2})[A-Za-z0-9-_.', ]*$/g;
  
    if (name === 'first_name' ||
        name === 'last_name' ||
        name === 'position' ||
        name === 'branch' ||
        name === 'address' ||
        name === 'agent'
    ) {
        isValid = nameRegex.test(input);
        return isValid;
    }

    const re = /^[A-Za-z0-9-_.@', ]*$/g;
    isValid = re.test(input);

    return isValid;
};
